import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <h1>Critical Joe</h1>
          <h2>Question everything</h2>
          <h3>Coming Soon !!!</h3>
      </header>
    </div>
  );
}

export default App;
